import React, { useState, useEffect } from 'react';
import { Nav, Modal, Button, Navbar, Container } from 'react-bootstrap';
import './Sidebar.css';
import { ReactComponent as HomeIcon } from './icons/home-button-svgrepo-com.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import ColorToggleButton from './ColorToggleButton';
import './LandingPage/LandingPage.css'
const Sidebar = () => {
  const [show, setShow] = useState(false);  
  const [sidebarWidth, setSidebarWidth] = useState('50px');  
  const [allowExpand, setAllowExpand] = useState(true);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
      if (window.matchMedia("(max-width: 768px)").matches) {
        setSidebarWidth('100%'); // Always expanded on mobile
      } else {
        setSidebarWidth('50px'); // Reset to default sidebar width on desktop
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    
      setAllowExpand(true); // Enable hover expand on desktop
    
  });

  // Function to handle modal opening and minimize sidebar
  const handleShow = (e) => {
    setShow(true);
    setSidebarWidth('50px');
    setAllowExpand(false); // Prevent immediate re-expansion
    if (e) e.currentTarget.blur(); // Removes focus from the Info button
  };

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      setAllowExpand(true); // Allows sidebar to expand after a delay 
    }, 300); 
  };

  const handleMouseOver = () => {
    if (!show && allowExpand) { // Only expands if modal is not shown 
      setSidebarWidth('150px');
    }
  };

  const handleMouseOut = () => {
    if (!show) {
      setSidebarWidth('50px');
    }
  };

if(!isMobile){
  return (
    <Nav
      className={`col-md-1 d-none d-md-block bg-dark ${isMobile ? 'topbar' : 'sidebar'}`}
      style={{ width: sidebarWidth }}
      onMouseOver={handleMouseOver }
      onMouseOut={handleMouseOut}
    >
      <Nav.Item className="sidebar-item">
        <Nav.Link href="/" className="nav-link">
          <HomeIcon className="home-icon" />
          <span className="nav-label">Home</span>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item className="sidebar-item">
        <Nav.Link as="button" className="nav-link" onClick={(e) => handleShow(e)}>
          <InfoIcon className="info-icon" />
          <span className="nav-label">Info</span>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item className="sidebar-item">
        <div className="color-toggle-wrapper">
          <div className="color-toggle-circle"></div>
          <div className="color-toggle">
            <ColorToggleButton />
          </div>
        </div>
      </Nav.Item>

      {/* Modal Component */}
      <Modal show={show} onHide={handleClose} dialogClassName="modal-custom">
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>To select a map, simply select a map from the list on the left.</li>
            <li>To filter desired utility, use the navigation bar on the left. The icons on the map will reflect various positions you can throw this utility.</li>
            <li>Upon selecting a grenade, a group of videos and icons representing positions from which you can throw them will appear. Click on one to learn new utility!</li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="button-custom">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Nav>
  );

  }return (
    <>
      <Navbar bg="dark" expand="lg" fixed="top" variant="dark" className={"landing-page-nav"}>
        <Container>
          <Navbar.Brand href="/">CS Util</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/" className="mobile-nav-link">
                <HomeIcon className="mobile-home-icon" />
                Home
              </Nav.Link>
              <Nav.Link className="mobile-nav-link" onClick={handleShow}>
                <InfoIcon className="mobile-info-icon" />
                Info
              </Nav.Link>
              <div className="mobile-color-toggle">
                <ColorToggleButton />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-custom">
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <ol>
              <li>To select a map, simply select a map from the list on the left.</li>
              <li>To filter desired utility, use the navigation bar on the left. The icons on the map will reflect various positions you can throw this utility.</li>
              <li>Upon selecting a grenade, a group of videos and icons representing positions from which you can throw them will appear. Click on one to learn new utility!</li>
            </ol>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="button-custom">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sidebar;