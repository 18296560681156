import React from 'react';
import { useSelector } from 'react-redux';
import './MapPane.css';

import { ReactComponent as FlashIcon } from "./utilityIcons/flash.svg";
import { ReactComponent as HegrenadeIcon } from "./utilityIcons/hegrenade.svg";
import { ReactComponent as MolotovIcon } from "./utilityIcons/molotov.svg";
import { ReactComponent as SmokeIcon } from "./utilityIcons/smoke.svg";

const icons = {
  smoke: SmokeIcon,
  flash: FlashIcon,
  molotov: MolotovIcon,
  hegrenade: HegrenadeIcon,
};

function MapPane(props) {
  
  const imagePath = require(`./maps/${props.selectedMap}Map.png`);
  const utilities = useSelector((state) => state.utilities.value);



  const getUtilityId = (utility, index) => `${utility.type}-${index}`;

  const handleIconClick = (utility, index) => {
    const utilityId = getUtilityId(utility, index);
    if (props.selectedUtilityId === utilityId) {
      // If the same icon is clicked again, clear the selection
      props.setSelectedUtilityId(null);
      props.setSelectedVideos([]);
      props.setMainVideo([]);
    } else {
      props.setSelectedUtilityId(utilityId);
      const videos = utility.thrownLocations.map(location => location.video);
      props.setSelectedVideos(videos);
      props.setMainVideo(videos[0]);
    }
    props.setSelectedUtilType(utility.type);
  };  

  const handleThrownClick = video => {
    props.setMainVideo(video);
  }
  const renderUtilityIcon = (utility, index) => {
    const IconComponent = icons[utility.type];
    const utilityId = getUtilityId(utility, index);
    return IconComponent ? (
      <div
        key={index}
        className={`icon`}
        style={{
          position: 'absolute',
          left: utility.locationX,
          top: utility.locationY,
          transform: 'translate(-50%, -50%)'
        }}
        onClick={() => handleIconClick(utility, index)}
      >
        <IconComponent className={`svg${utilityId === props.selectedUtilityId ? '-selected' : ''}`} />
      </div>
    ) : null;
  };

  const renderThrownLocations = (utility, index) => {
    const utilityId = getUtilityId(utility, index);
    if (props.selectedUtilityId === utilityId) {
      return utility.thrownLocations.map((location, locIndex) => (
        <React.Fragment key={locIndex}>
          <div
            className="thrown-location"
            style={{
              position: 'absolute',
              left: location.locationX,
              top: location.locationY,
              transform: 'translate(-50%, -50%)'
            }}
            onClick={() => handleThrownClick(location.video)}
          >
          </div>
          <svg
            className="line-connector"
            style={{
              position: 'absolute',
              left: '0',
              top: '0',
              width: '100%',
              height: '100%',
            }}
          >
            <line
              x1={`${parseFloat(utility.locationX)}%`}
              y1={`${parseFloat(utility.locationY)}%`}
              x2={`${parseFloat(location.locationX)}%`}
              y2={`${parseFloat(location.locationY)}%`}
              stroke="blue"
              strokeDasharray="4"
              strokeWidth="2"
            />
          </svg>
        </React.Fragment>
      ));
    }
    return null;
  };

  return (
    <div className="map-container">
      <div
        className="map-wrapper"
        style={{ backgroundImage: `url(${imagePath})` }}
      >

        
      {utilities.filter(utility =>
        utility.map.toLowerCase() === props.selectedMap.toLowerCase() && props.selectedUtil.includes(utility.type)
      ).map((utility, index) => (
        <React.Fragment key={index}>
          {renderUtilityIcon(utility, index)}
          {renderThrownLocations(utility, index)}
        </React.Fragment>
      ))}
      </div>
    </div>
  );
}

export default MapPane;
