import { createSlice } from '@reduxjs/toolkit';

export const mapSlice = createSlice({
  name: 'map',
  initialState: 'ancient', // Default map
  reducers: {
    setMap: (state, action) => action.payload,
  },
});
 
export const { setMap } = mapSlice.actions;

export default mapSlice.reducer;
