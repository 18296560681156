import "bootstrap/dist/css/bootstrap.min.css";
import MapNav from "./MapNav.js";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from "./LandingPage/LandingPage.js";
import MapPane from "./MapPane.js";
import VideoPane from "./VideoPane.js";
import "./App.css";
import React, { useState, useEffect } from "react";
import {useDispatch} from "react-redux"
import UtilNavBar from "./UtilNavBar.js";
import Sidebar from "./Sidebar.js";
import { setMap } from "./mapSlice.js";
import { setEnabledUtil } from "./enabledUtilSlice.js";
import store from "./store.js";

import { fetchUtilitiesByMapAndTypes } from "./UtilitySlice.js";
function App() {

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme") || "light";
    document.documentElement.setAttribute("data-bs-theme", currentTheme);

    if (currentTheme === "dark") {
      document.body.classList.add("dark-mode-gradient");
    } else {
      document.body.classList.remove("dark-mode-gradient");
    }
  }, []);

  const [selectedMap, setSelectedMap] = useState("ancient");
  const [selectedUtil, setSelectedUtil] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [mainVideo, setMainVideo] = useState(null);
  const [selectedUtilityId, setSelectedUtilityId] = useState(null);
  const [selectedUtilType, setSelectedUtilType] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch setMap action whenever selectedMap changes
    if (selectedMap) {
      dispatch(setMap(selectedMap));

    }

    // Dispatch setSelectedUtilType action whenever selectedUtilType changes
    if (selectedUtil) {
      dispatch(setEnabledUtil(selectedUtil));    

    }

    // Dispatch fetchUtilitiesByMapAndTypes whenever either change (after others bc async)
    dispatch(fetchUtilitiesByMapAndTypes());
    

  }, [selectedMap, selectedUtil,  dispatch]);


  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/learn" element={
            <>
              <Sidebar />

              <div className="MainSection-container">
              <MapNav selectedMap={selectedMap} setSelectedMap={setSelectedMap} setSelectedUtilityId={setSelectedUtilityId} setMainVideo={setMainVideo} setSelectedVideos={setSelectedVideos} />
              <div className="UtilNavBar">
                <UtilNavBar setSelectedUtilityId = {setSelectedUtilityId} selectedUtilType = {selectedUtilType} setSelectedUtil={setSelectedUtil} setSelectedVideos = {setSelectedVideos} setMainVideo = {setMainVideo} />
              </div>
              <div className="MapPane">
                <MapPane setSelectedUtilType = {setSelectedUtilType} selectedUtilityId={selectedUtilityId} selectedMap={selectedMap} selectedUtil={selectedUtil} setSelectedVideos={setSelectedVideos} setMainVideo={setMainVideo} setSelectedUtilityId={setSelectedUtilityId} />
              </div>
              <VideoPane setSelectedVideos = {setSelectedVideos} selectedVideos={selectedVideos} setMainVideo={setMainVideo} mainVideo={mainVideo} selectedUtil={selectedUtil} selectedMap={selectedMap}/>
            </div>
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;