import { configureStore } from "@reduxjs/toolkit";
import utilitySlice from "./UtilitySlice";
import mapSlice from "./mapSlice";
import enabledUtilSlice from "./enabledUtilSlice";

export default configureStore({
  reducer: {
    utilities: utilitySlice,
    map: mapSlice,
    enabledUtil: enabledUtilSlice,
  },
});
