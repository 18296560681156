import { createSlice } from '@reduxjs/toolkit';

export const enabledUtilSlice = createSlice({
  name: 'enabledUtil',
  initialState: [[]], // Default enabledUtil
  reducers: {
    setEnabledUtil: (state, action) => action.payload,
  },
});
 
export const { setEnabledUtil } = enabledUtilSlice.actions;

export default enabledUtilSlice.reducer;
