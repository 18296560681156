import Nav from "react-bootstrap/Nav";
import "./MapNav.css";

const navLinks = [
  { eventKey: "ancient", displayName: "" },
  { eventKey: "anubis", displayName: "" },
  { eventKey: "dust2", displayName: "" },
  { eventKey: "inferno", displayName: "" },
  { eventKey: "mirage", displayName: "" },
  { eventKey: "nuke", displayName: "" },
  { eventKey: "overpass", displayName: "" },
  { eventKey: "vertigo", displayName: "" },
];

function MapNav(props) {
 

  return (
    <Nav
      className="scroll-container"
      onSelect={(selectedKey) => {
        props.setSelectedMap(selectedKey);
        props.setSelectedUtilityId(null);
        props.setSelectedVideos([]);
        props.setMainVideo(null);
      }}
    >
      {navLinks.map((link) => (
        <Nav.Item key={link.eventKey}>
          <Nav.Link
            eventKey={link.eventKey}
            className={`map-nav ${link.eventKey}-background ${
              props.selectedMap === link.eventKey ? "selected" : ""
            }`}
          >
            {link.displayName}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
}

export default MapNav;
