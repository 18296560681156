import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUtilitiesByMapAndTypes = createAsyncThunk(
  'utilities/fetchByMapAndTypes',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { map, enabledUtil } = getState(); 
      const queryParams = enabledUtil.map(type => `types=${type}`).join('&');
      const response = await fetch(`https://uw2icoiqy3qoq3fj34deedlkti0iqudj.lambda-url.us-east-2.on.aws/thrownlocations?map=${map}&${queryParams}`);
      if (!response.ok) throw new Error('Network response error');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const utilitySlice = createSlice({
  name: 'utility',
  initialState: {
    value: [],
    status: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUtilitiesByMapAndTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUtilitiesByMapAndTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.value = action.payload;
      })
      .addCase(fetchUtilitiesByMapAndTypes.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default utilitySlice.reducer;
