import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './VideoPane.css'; 
import { useSelector } from 'react-redux'

function VideoPane(props) {


  const utilities = useSelector(state => state.utilities.value);
  const getAllVideos = () => {
    return utilities
      .filter(utility =>
        utility["map"].toLowerCase() === props.selectedMap.toLowerCase() && props.selectedUtil.includes(utility.type.toLowerCase())
      )
      .flatMap(utility => utility.thrownLocations.map(location => location.video));
      
  };


  const videosExistHelper = (func, funcArgs, all) => props.selectedVideos && props.selectedVideos.length > 0 ? func(funcArgs) : all ? func(getAllVideos()) : null;

  const renderMainVideo = (video) => {
    const videoSrc = `https://youtube.com/embed/${video.id}`;
    return (
      <div className="video-item">
        <div className="video-title">{video.title}</div>
        <div className="video-wrapper">
          <iframe
            src={videoSrc}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title={video.title}
            className="main-video-iframe"
          ></iframe>
        </div>
      </div>
    );
  };

  const renderVideoList = (videos) => {
    return videos.filter(v => props.mainVideo ? v.id !== props.mainVideo.id : v.id).map((video) => (
      <Col key={video.id} xs={12} md={6}>
        <div className="video-item">
          <div className="video-title">{video.title}</div>
          <div className="video-wrapper">
            <iframe
              src={`https://youtube.com/embed/${video.id}`}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={video.title}
              className="video-list-iframe"
            ></iframe>
          </div>
        </div>
      </Col>
    ));
  };

  return (
    <Container className="video-pane">
      <Row>
        <Col xs={12}>
          {videosExistHelper(renderMainVideo, props.mainVideo, false)}
        </Col>
      </Row>
      <Row className="video-list-row">
        {videosExistHelper(renderVideoList, props.selectedVideos, true)}
      </Row>
    </Container>
  );
}

export default VideoPane;
