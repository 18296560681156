import {React} from 'react';
import './ColorToggleButton.css';
import './App.css'; 

if(localStorage.getItem("theme") === null){
  
  const currentColor = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  localStorage.setItem('theme', currentColor);
}

function ColorToggleButton() {
  const toggleColor = () => { 
 
    const currentColor = localStorage.getItem('theme') === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', currentColor);
    document.documentElement.setAttribute('data-bs-theme', currentColor);

    // Toggle the dark mode gradient background
    if (currentColor === 'dark') {
      document.body.classList.add('dark-mode-gradient');
    } else {
      document.body.classList.remove('dark-mode-gradient');
    }
  };

  return (
    <div className="ColorButton-container"> 
      <label className="switch ColorButton"> 
        <input type="checkbox" onChange={toggleColor} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default ColorToggleButton;