import React, { useState } from 'react';
import './UtilNavBar.css';
import { ReactComponent as FlashIcon } from './utilityIcons/flash.svg';
import { ReactComponent as HegrenadeIcon } from './utilityIcons/hegrenade.svg';
import { ReactComponent as MolotovIcon } from './utilityIcons/molotov.svg';
import { ReactComponent as SmokeIcon } from './utilityIcons/smoke.svg';

const icons = [
  { name: 'smoke', Component: SmokeIcon },
  { name: 'flash', Component: FlashIcon },
  { name: 'molotov', Component: MolotovIcon },
  { name: 'hegrenade', Component: HegrenadeIcon }
];

const UtilNavBar = props => {
  const [selectedIcons, setSelectedIcons] = useState([]);

  const handleIconClick = (iconIndex) => {
    let newSelectedIcons = [...selectedIcons];

    if (newSelectedIcons.includes(iconIndex)) { // removes
      newSelectedIcons = newSelectedIcons.filter(idx => idx !== iconIndex);
    } else { // otherwise selects
      newSelectedIcons.push(iconIndex);
      newSelectedIcons.sort();
    }

    setSelectedIcons(newSelectedIcons);
    
    const selectedUtils = newSelectedIcons.map(idx => icons[idx].name);
    props.setSelectedUtil(selectedUtils);

    if(!selectedUtils || !props.selectedUtilType || !selectedUtils.includes(props.selectedUtilType.toLowerCase())){
      props.setSelectedVideos(null);
      props.setMainVideo(null);
      props.setSelectedUtilityId(null);
    }
  };

  const getAdjacentGroups = (selectedIcons) => {
    const groups = [];
    let currentGroup = [];

    selectedIcons.forEach((iconIndex, i) => {
      currentGroup.push(iconIndex);
      if (selectedIcons[i + 1] !== iconIndex + 1) { // ensures gap
        groups.push([...currentGroup]);
        currentGroup = [];
      }
    });

    return groups;
  };

  const adjacentGroups = getAdjacentGroups(selectedIcons); 

  const isTopmostInGroup = (index, group) => { // topmost group to ensure there is only one "oval" per group
    return group.indexOf(index) === 0;
  };


  const isPartOfGroup = (index, group) => { // part of group but NOT top
    return group.includes(index) && group.indexOf(index) !== 0;
  };

  return (
    <div className="vertical-navbar">
      {icons.map((icon, index) => {
        let groupSize = 0;
        let isTopmost = false;
        let isInGroup = false;
        adjacentGroups.forEach(group => {
          if (group.includes(index)) {
            groupSize = group.length;
            isTopmost = isTopmostInGroup(index, group);
            isInGroup = isPartOfGroup(index, group);
          }
        });

        const isSelected = selectedIcons.includes(index);
        const applySelectedClass = isSelected && (groupSize === 0 || isTopmost); // either not in group or top

        return (
          <div
            key={icon.name}
            className={`icon ${applySelectedClass ? 'selected' : ''} ${isTopmost && groupSize > 1 ? `group-top-${groupSize}` : ''} ${isInGroup ? 'part-of-group' : ''}`}
            onClick={() => handleIconClick(index)}
          >
            <icon.Component className="icon-svg" alt={icon.name} />
          </div>
        );
      })}
    </div>
  );
};

export default UtilNavBar;