import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import './LandingPage.css'; 
import ColorToggleButton from '../ColorToggleButton';


import {useLocation} from 'react-router-dom'
const LandingPage = () => {
    const location = useLocation();

    React.useEffect(() => {
        Events.scrollEvent.register('begin', function() {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function() {
            console.log("end", arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);


    const isLandingPage = location.pathname === '/'; // Adjust the path as per your landing page route

    return (
        <>
            <Navbar bg="dark" expand="lg" fixed="top" variant="dark" className={"landing-page-nav"}>                
            <Container>
                <Navbar.Brand className="title" onClick={() => scroll.scrollToTop({ duration: 100 })}>CS Util</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            {/* Only apply activeClass if isLandingPage is true */}
                            <Link activeClass={isLandingPage ? "active" : ""} to="learn" spy={true} smooth={true} offset={-70} duration={100} className="nav-link">Learn</Link>
                            <Link activeClass={isLandingPage ? "active" : ""} to="roundPlanner" spy={true} smooth={true} offset={-70} duration={100} className="nav-link">Round Planner</Link>
                            <Link activeClass={isLandingPage ? "active" : ""} to="aboutUs" spy={true} smooth={true} offset={-70} duration={100} className="nav-link">About Us</Link>
                        </Nav>
                        <ColorToggleButton />
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Element name="learn" className="section">
                <Container>
                    <div className="section-content">
                        <h2 className="section-header">Learn</h2>
                        <p className="section-text">Learn lineups with an interactive map!</p>
                        <Button href="/learn" className="learn-button" variant="primary">Get Started</Button>
                    </div>
                </Container>
            </Element>

            <Element name="roundPlanner" className="section">
                <Container>
                    <div className="section-content">
                        <h2 className="section-header">Round Planner</h2>
                        <p className="section-text">Coming Soon!</p>
                    </div>
                </Container>
            </Element>

            <Element name="aboutUs" className="section">
                <Container>
                    <div className="section-content">
                        <h2 className="section-header">About Us</h2>
                        <p className="section-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                </Container>
            </Element>
        </>
    );
};

export default LandingPage;